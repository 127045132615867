/* Naming of colors and fonts inspired by Uber Base (https://base.uber.com/6d2425e9f/p/8898fb-color-legacy/b/065744) */

:root {
	--color-white: hsl(0, 0%, 98%);

	/* Foundation Colors */
	--color-negative: hsl(360, 100%, 48%);
	--color-warning: hsl(25, 100%, 45%);
	--color-positive: hsl(105, 87%, 35%);

	/* Core Colors */
	--color-background-primary: hsl(0, 0%, 98%);
	--color-background-secondary: hsl(0, 0%, 95%);
	--color-background-tertiary: hsl(0, 0%, 90%);
	--color-background-inverse-primary: hsl(0, 0%, 5%);
	--color-background-inverse-secondary: hsl(0, 0%, 12%);

	--color-content-primary: hsl(0, 0%, 5%);
	--color-content-secondary: hsl(0, 0%, 36%);
	--color-content-tertiary: hsl(0, 0%, 50%);
	--color-content-inverse-primary: hsl(0, 0%, 95%);
	--color-content-inverse-secondary: hsl(0, 0%, 68%);
	--color-content-inverse-tertiary: hsl(0, 0%, 30%);

	--color-backdrop: color-mix(in hsl, hsl(0, 0%, 10%), transparent var(--transparency-backdrop-filter-navigation-bar));
	--color-backdrop-theme: hsl(0, 0%, 12%); /* used as theme color, when modal is used */
	--backdrop-filter-navigation-bar: blur(calc(10rem / 16)) saturate(1.3);

	--color-border-input: hsl(0, 0%, 80%);

	--transparency-backdrop-filter-navigation-bar: 10%; /* when color is used in combination with --backdrop-filter-navigation-bar */

	@media (prefers-color-scheme: dark) {
		/* Core Colors */
		--color-background-primary: hsl(0, 0%, 4%);
		--color-background-secondary: hsl(0, 0%, 6%);
		--color-background-tertiary: hsl(0, 0%, 15%);
		--color-background-inverse-primary: hsl(0, 0%, 98%);
		--color-background-inverse-secondary: hsl(0, 0%, 95%);

		--color-content-primary: hsl(0, 0%, 95%);
		--color-content-secondary: hsl(0, 0%, 68%);
		--color-content-tertiary: hsl(0, 0%, 30%);
		--color-content-inverse-primary: hsl(0, 0%, 5%);
		--color-content-inverse-secondary: hsl(0, 0%, 50%);
		--color-content-inverse-tertiary: hsl(0, 0%, 30%);

		--color-border-input: hsl(0, 0%, 25%);
	}
}
