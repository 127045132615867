.m-stack {
	--_wrap: var(--wrap, warp);
	--_align: var(--align, center);
	--_gap: var(--gap, var(--gap-small));

	display: flex;
	flex-wrap: var(--_wrap);
	gap: var(--_gap);
	align-items: var(--_align);
}
