.a-text {
	--font-size: var(--font-size-paragraphs-medium);
	--line-height: var(--line-height-paragraphs-medium);

	font: var(--font-paragraphs-medium);

	> h1 {
		font: var(--font-heading-xxlarge);
	}
	> h2 {
		font: var(--font-heading-xlarge);
	}
	> h3 {
		font: var(--font-heading-large);
	}
	> h4 {
		font: var(--font-heading-medium);
	}
	> h5 {
		font: var(--font-heading-small);
	}
	> h6 {
		font: var(--font-heading-xsmall);
	}

	> * + * {
		margin-block-start: calc(var(--gap-block) * 0.75 + var(--typo-margin-top) + var(--typo-margin-bottom));
	}

	&.-large {
		--font-size: var(--font-size-paragraphs-large);
		--line-height: var(--line-height-paragraphs-large);

		font: var(--font-paragraphs-large);
	}
	&.-small {
		--font-size: var(--font-size-paragraphs-small);
		--line-height: var(--line-height-paragraphs-small);

		font: var(--font-paragraphs-small);
	}
	&.-xsmall {
		--font-size: var(--font-size-paragraphs-xsmall);
		--line-height: var(--line-height-paragraphs-xsmall);

		font: var(--font-paragraphs-xsmall);
	}

	& + & {
		margin-block-start: calc(var(--font-size) * var(--line-height));
	}
	&.-large + & {
		margin-block-start: var(--gap-block);
	}

	.a-heading + & {
		margin-block-start: var(--gap-block);
	}
}
