/**
 * Each size should divide by 4.
 */

:root {
	/* Font family */
	--font-family: -apple-system, Helvetica, sans-serif;

	/* Font size */
	--font-size-display-large: calc(45rem / 16);
	--font-size-display-medium: calc(24rem / 16);
	--font-size-display-small: calc(21rem / 16);

	--font-size-heading-medium: calc(16rem / 16);

	--font-size-label-medium: calc(16rem / 16);
	--font-size-label-small: calc(14rem / 16);
	--font-size-label-xsmall: calc(12rem / 16);

	--font-size-paragraphs-medium: calc(16rem / 16);
	--font-size-paragraphs-small: calc(14rem / 16);
	--font-size-paragraphs-xsmall: calc(11rem / 16);

	--font-size: var(--font-size-paragraphs-medium);

	/* Line height */
	--line-height-display-large: calc(48rem / 16);
	--line-height-display-medium: calc(28rem / 16);
	--line-height-display-small: calc(24rem / 16);

	--line-height-heading-medium: calc(20rem / 16);

	--line-height-label-medium: calc(20rem / 16);
	--line-height-label-small: calc(16rem / 16);
	--line-height-label-xsmall: calc(12rem / 16);

	--line-height-paragraphs-medium: calc(20rem / 16);
	--line-height-paragraphs-small: calc(16rem / 16);
	--line-height-paragraphs-xsmall: calc(12rem / 16);

	--line-height: var(--line-height-paragraphs-medium);

	/* Font */
	--font-display-large: 800 var(--font-size-display-large)/var(--line-height-display-large) var(--font-family);
	--font-display-medium: 800 var(--font-size-display-medium)/var(--line-height-display-medium) var(--font-family);
	--font-display-small: 800 var(--font-size-display-small)/var(--line-height-display-small) var(--font-family);

	--font-heading-medium: 800 var(--font-size-heading-medium)/var(--line-height-heading-medium) var(--font-family);

	--font-label-medium: 600 var(--font-size-label-medium)/var(--line-height-label-medium) var(--font-family);
	--font-label-small: 600 var(--font-size-label-small)/var(--line-height-label-small) var(--font-family);
	--font-label-xsmall: 600 var(--font-size-label-xsmall)/var(--line-height-label-xsmall) var(--font-family);

	--font-paragraphs-medium: 400 var(--font-size-paragraphs-medium)/var(--line-height-paragraphs-medium) var(--font-family);
	--font-paragraphs-small: 400 var(--font-size-paragraphs-small)/var(--line-height-paragraphs-small) var(--font-family);
	--font-paragraphs-xsmall: 400 var(--font-size-paragraphs-xsmall)/var(--line-height-paragraphs-xsmall) var(--font-family);

	--font: var(--font-paragraphs-medium);

	/* Letter spacing */
	--leter-spacing-display: -0.003em;

	/* Other */
	--hyphenate-limit: 7;
}
