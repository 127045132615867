.a-category-list-item {
	--_padding: var(--gap-small);

	display: grid;
	padding: var(--_padding);
	row-gap: var(--gap-xxsmall);
	text-decoration: none;
	background-color: var(--color-background-primary);
	transition-duration: var(--duration-small);
	transition-property: background-color;

	> * {
		display: block;
	}

	> strong {
		font: var(--font-heading-medium);
	}

	> small {
		font: var(--font-label-xsmall);
		color: var(--color-content-secondary);
	}

	@media not (pointer: coarse) {
		&:is(:hover, :focus-visible),
		.container:has(.button-edit):is(:hover, :focus-visible) & {
			background-color: color-mix(in hsl, var(--color-background-primary), var(--color-content-primary) 2%);
		}
	}
}
