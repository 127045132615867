.a-icon {
	--size: 1lh;

	> svg {
		block-size: var(--size);
	}
	> svg * {
		fill: currentcolor;
	}

	&[data-size="large"] {
		--size: calc(28 / 20 * 1lh);
	}
}
