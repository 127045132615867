/* Template components can only apply to the body element */
.t-bookmark-category {
}

.t-bookmark-category > header {
	grid-row: header;
	z-index: 1;
	position: sticky;
	inset-block-start: 0;
	padding-block-end: var(--gap-small);
	padding-inline-end: var(--gap-xsmall);
	display: flex;
	align-items: start;
	justify-content: space-between;
	border-block-end: var(--border-primary);
	background-color: color-mix(in hsl, var(--color-background-primary), transparent var(--transparency-backdrop-filter-navigation-bar));;
	backdrop-filter: var(--backdrop-filter-navigation-bar);
}

.t-default__search-form {
	padding: var(--gap-small);
}

.t-bookmark-category main {
	margin-inline: auto;
	padding-block-end: calc(83rem / 16);
	inline-size: 100%;
	overflow: hidden;

	@media (min-width: calc(800em /16)) {
		max-inline-size: calc(100% - var(--gap-medium) * 2);
	}
}
