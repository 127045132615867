.a-input {
	box-sizing: border-box;
	inline-size: 100%;
	padding-block: calc(5rem / 16);
	padding-inline: calc(12rem / 16);
	font: inherit;
	border-radius: var(--border-radius-xxsmall);
	border: var(--border-secondary);
	background-color: var(--color-background-primary);

	textarea& {
		resize: none;
	}
}
