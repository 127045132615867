.m-bookmarks-header {
	display: grid;
	grid-template-columns: auto minmax(10px, 1fr);
	gap: var(--gap-xxxsmall);
	align-items: start;

	h1 {
		margin-block-start: var(--gap-xsmall);
		font: var(--font-display-medium);
	}
	h2 {
		font: var(--font-label-xsmall);
		color: var(--color-content-secondary);
	}
}

.m-bookmarks-header__button-back {
	flex-shrink: 0;
}
