@keyframes opacity {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes rotate {
	from {
		rotate: 0deg;
	}
	to {
		rotate: 360deg;
	}
}
