/**
 * Each size should divide by 4.
 */

:root {
	/* Grid */
	--gap-xxxsmall: calc(2rem / 16);
	--gap-xxsmall: calc(4rem / 16);
	--gap-xsmall: calc(8rem / 16);
	--gap-small: calc(12rem / 16);
	--gap-medium: calc(16rem / 16);
	--gap-large: calc(28rem / 16);

	/* Sizes */
	--max-width: calc(1600rem / 16);
	--max-width-content: calc(1080rem / 16);
	--max-width-text: calc(680rem / 16);

	/* Border */
	--border-radius-xxsmall: calc(1rem / 16);
	--border-radius-xsmall: calc(2rem / 16);
	--border-radius-small: calc(4rem / 16);
	--border-radius-medium: calc(6rem / 16);
	--border-radius-large: calc(12rem / 16);
	--border-radius-xlarge: calc(20rem / 16);

	/* Z-Index */
	--z-index-modal: 10;
}
