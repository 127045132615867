.m-bookmark-categories {
	--_min-column-size: calc(340rem / 16);

	margin: calc(-1rem / 16);
	display: grid;
	justify-content: center;
	list-style: none;
	grid-template-columns: repeat(auto-fill, minmax(min(100vw, var(--_min-column-size)), 1fr));

	> li {
		background-color: var(--color-background-primary);
		border: var(--border-tertirary);
	}
}
