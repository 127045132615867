@keyframes rotate {
	from {
		rotate: 0deg;
	}
	to {
		rotate: 360deg;
	}
}

.a-button {
	--_color-content: var(--color-content-inverse-primary);
	--_color-content-disabled: var(--color-content-inverse-tertiary);
	--_color-background: var(--color-background-inverse-primary);
	--_font: var(--font-label-medium);
	--_border-radius: var(--border-radius-xsmall);
	--_padding-inline: var(--gap-small);
	--_padding-block: var(--gap-xsmall);
	--_gap: var(--gap-xx-small);
	--_duration: var(--duration-medium);

	display: inline-flex;
	justify-content: center;
	align-items: center;
	column-gap: var(--_gap);
	font: var(--_font);
	color: var(--_color-content);
	text-decoration: none;
	padding-block: var(--_padding-block);
	padding-inline: var(--_padding-inline);
	white-space: nowrap;
	cursor: pointer;
	border: 0;
	border-radius: var(--_border-radius);
	background-color: var(--_color-background);
	transition-property: background-color, color;
	transition-duration: var(--_duration);
	touch-action: manipulation;

	&[data-size="xsmall"] {
		--_font: var(--font-label-xsmall);
		--_padding-inline: var(--gap-xsmall);
		--_padding-block: calc(6rem / 10);
	}
	&[data-size="small"] {
		--_font: var(--font-label-small);
	}
	&[data-size="large"] {
		--_font: var(--font-label-large);
	}

	&[data-shape="round"] {
		--_border-radius: 50%;
		--_padding-inline: var(--_padding-block);
		min-inline-size: calc(1lh + var(--_padding-inline) * 2);
		text-align: center;
	}
	&[data-shape="squared"] {
		--_padding-inline: var(--_padding-block);
		min-inline-size: calc(1lh + var(--_padding-inline) * 2);
		text-align: center;
	}

	&[data-kind="secondary"] {
		--_color-content: var(--color-content-primary);
		--_color-content-disabled: var(--color-content-tertiary);
		--_color-background: var(--color-background-tertiary);
	}
	&[data-kind="tertiary"] {
		--_color-content: var(--color-content-primary);
		--_color-content-disabled: var(--color-content-tertiary);
		--_color-background: transparent;
	}

	&[data-theme="negative"] {
		--_color-content: var(--color-white);
		--_color-background: var(--color-negative);
	}
	&[data-theme="negative"][kind="secondary"] {
		--_color-content: var(--color-negative);
		--_color-content-disabled: var(--color-content-tertiary);
		--_color-background: var(--color-background-tertiary);
	}

	&[data-variant="back"] {
		--_padding-inline: var(--gap-xxsmall);
	}

	&[data-full-width] {
		display: flex;
		inline-size: 100%;
	}

	&[data-loading] {
		position: relative;
		color: color-mix(in hsl, var(--_color-content), transparent 95%);
		cursor: auto;

		&::before {
			position: absolute;
			content: '';
			block-size: 1lh;
			inline-size: 1lh;
			mask-image: url(/assets/symbols/loader-600-large.svg);
			mask-size: 1lh;
			background-color: var(--_color-content);
			animation-name: rotate;
			animation-duration: var(--duration-rotate-loader);
			animation-timing-function: linear;
			animation-iteration-count: infinite;
		}
	}
}
